<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">Age Group</h1>
    </div>
    <div class="title-tabs mt-3">Age Group</div>
    <b-container class="no-gutters bg-white" v-if="isLoading">
      <div class="pt-3">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="branch_name_en"
              v-model="form.name"
              :v="$v.form.name"
              :isValidate="$v.form.name.$error"
              isRequired
            />
          </b-col>
          <div class="break-normal"></div>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="From"
              placeholder="From"
              type="number"
              name="from"
              oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
              v-model="form.from_age"
              :v="$v.form.from_age"
              :isValidate="$v.form.from_age.$error"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="To"
              placeholder="To"
              type="number"
              name="to"
              oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
              v-model="form.to_age"
              :v="$v.form.to_age"
              :isValidate="$v.form.to_age.$error"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>

    <FooterAction routePath="/setting/age-group" @submit="save()" />
  </div>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import minValue from "vuelidate/lib/validators/minValue";
export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        name: "",
        from_age: "",
        to_age: "",
      },
      isLoading: true,
    };
  },
  validations: {
    form: {
      name: { required },
      from_age: { required, minValue: minValue(0) },
      to_age: { required, minValue: minValue(0) },
    },
  },
  created() {
    if (this.form.id != 0) {
      this.getList();
    }
  },

  methods: {
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      var res = {};
      if (this.form.sort_order == "") {
        this.form.sort_order = 0;
      }
      this.$EventBus.$emit("showLoading");
      if (this.form.id == 0) {
        res = await this.$Axios.post(
          `${process.env.VUE_APP_API}/Setting/CreateAgeGroup`,
          this.form
        );
      } else {
        res = await this.$Axios.put(
          `${process.env.VUE_APP_API}/Setting/UpdateAgeGroup`,
          this.form
        );
      }
      this.$EventBus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert().then(() => {
          this.$router.push("/setting/age-group");
        });
      } else this.errorAlert(res.data.message);
    },
    async getList() {
      this.isBusy = true;
      const response = await this.$Axios(
        `${process.env.VUE_APP_API}/Setting/GetAgeGroup/${this.form.id}`
      );
      this.form = response.data.detail;
      this.isBusy = false;
    },
  },
};
</script>
